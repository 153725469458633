const __DEV__ = process.env.NODE_ENV !== 'production';

export function trackEvent(name, payload) {
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.log(`[Track Event] ${name}`, payload);
  } else {
    if (typeof window.amplitude === `object`) {
      window.amplitude.getInstance().logEvent(name, payload);
    }
  }
}

export function trackSessionStart(location) {
  trackEvent(
    'Start session',
    location
      ? {
          urlPath: location.pathname,
          urlQueryString: location.search,
          urlHash: location.hash,
        }
      : {}
  );
}

export function trackPageview(location) {
  if (typeof sessionStorage !== 'undefined') {
    const storage = window.sessionStorage;
    if (!storage.getItem('hasTrackedSessionStart')) {
      trackSessionStart(location);
      storage.setItem('hasTrackedSessionStart', true);
    }
  }

  trackEvent('Pageview', {
    urlPath: location.pathname,
    urlQueryString: location.search,
    urlHash: location.hash,
  });
}
