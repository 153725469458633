/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { trackPageview } from './src/tracking';

export const onRouteUpdate = ({ location }) => {
  trackPageview(location);
};

export const shouldUpdateScroll = () => {
  return false;
};
